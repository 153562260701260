import {HashRouter, BrowserRouter, Route, Routes} from "react-router-dom";
import Signin from '../components/Signin'
import Tabs from '../components/Tabs'

export default function Router() {
  return (
    <div>
      <HashRouter basename='/'>
        <Routes>
          <Route path='/' element={<Signin />}></Route>
          <Route path='/archive' element={<Tabs />}></Route>
        </Routes>
      </HashRouter>
    </div>
  )
}
