import React, {useState} from 'react'
import {Box, Grid, Typography} from '@mui/material'
import {Card, Button, Link, CardContent, CardActionArea, CardMedia, CardActions} from '@mui/material'
import {Contents} from './Tabs'


/** card component */
const VCLink: React.FC<Contents> = (props): JSX.Element => {

    const {id, category: type, thumbnail, title} = props

    function cplink() {
        navigator.clipboard.writeText(thumbnail + "")
    }

    return (
        <Card variant='outlined'>
            <Grid container columns={{xs: 8, sm: 8, md: 12}} justifyContent='center' alignItems='center'>
                {/** main */}
                <iframe src={thumbnail} style={{width: '300px', height: '330px'}}></iframe>

                {/** sub info */}
              <Typography sx={{fontSize: 16, overflow: 'hidden', textOverflow: 'ellipsis'}} color='text.secondary'>
                {title}
              </Typography>
                <Button sx={{pt: 0, height: 20}} onClick={cplink}>
                    copy link
                </Button>
                <Link sx={{pt: 0, height: 20}} href={thumbnail}>
                    open link
                </Link>
            </Grid>
        </Card>
    )
}
export default VCLink

